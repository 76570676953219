<template>
   <div class="Communication">
      <h6 class="title">
         <Icon icon="iconGroup" />Kommunikation
      </h6>
      <div class="editor">
         <div class="paramsRow">
            <div class="items">
               <div class="item">
                  <DatePicker class="basic"
                     displayFormat="toEuDate"
                     :options="datePickerOptions"
                     v-model="date" />
               </div>

               <div class="item">
                  <Dropdown class="slim"
                     placeholder="Kundenkontakt"
                     :options="contactDropdownOptions"
                     v-model="contactId" />
               </div>
            </div>


            <div class="item middle">

            </div>
            <div class="item right">
               <DatePicker class="basic"
                  placeholder="nächster Kontakt"
                  displayFormat="toEuDate"
                  :options="{ clearable: true }"
                  v-model="dateNextContact" />
            </div>
         </div>
         <div class="textarea new">
            <Textarea placeholder="neue Notiz..."
               title="Shift.Enter für Zeilenumbruch"
               :onEnter="onTextareaEnter"
               v-model="comment" />
            <div class="col right">
               <div class="btn"
                  :class="{ active: comment && comment.trim().length > 0 }"
                  @click="onCommentSend">
                  <Icon icon="iconSend" />
               </div>
            </div>
         </div>
      </div>

      <div class="comments">
         <div v-for="comment in commentsSorted"
            :key="comment._id"
            class="comment">
            <div class="col left">
               <div class="commentHeader">
                  <div class="left">
                     <div class="month">{{ getDate(comment.date) }}</div>
                     <div class="contactName">{{ comment.contactName }} </div>
                  </div>
                  <div class="middle">
                     <div class="userName">{{ comment.userName }}</div>
                  </div>
                  <div class="right">
                     <div v-if="comment.dateNextContact"
                        class="nextContact">{{ `nächster Kontakt ${getDate(comment.dateNextContact)}` }}</div>
                  </div>
               </div>
               <Textarea class="text"
                  :readonly="true"
                  :value="comment.comment" />
            </div>
            <div class="col right hoverMenu">
               <div class="flyOutBtn"
                  @click="onCommentEdit(comment._id)">
                  <div class="btn">
                     <Icon icon="iconEdit" />
                  </div>
               </div>
            </div>
            <!-- <div class="col right hoverMenu">
               <button class="flyOutBtn"
                  :popovertarget="`menu${comment._id}`"
                  popovertargetaction="toggle">
                  <div class="btn">
                     <Icon icon="iconEdit" />
                  </div>
               </button>
               <div :id="`menu${comment._id}`"
                  popover
                  class="flyOut">
                  <div class="btn"
                     @click.stop="onCommentDelete(comment._id)">
                     <Icon icon="iconDelete" />
                  </div>
               </div>
            </div> -->
         </div>
      </div>
   </div>
</template>

<script>
import portalStateP from '@components/Portal/portalState';
const portalState = portalStateP()


import CheckBox from '@components/Form/CheckBox.vue';
import Icon from "@icons/Icon.vue"
import { Comment, Send, Delete, Edit } from "@icons/appFabric/icons"
import utils from "@SyoLab/utils"
import api from '@components/Portal/api';
import { DateTime, Timestamp, Day } from '@SyoLab/date-time';
import Textarea from '@components/Form/Textarea.vue';
import DatePicker from '@components/Form/DatePicker.vue';
import Dropdown from '@components/Form/Dropdown.vue';


export default {
   name: 'Communication',
   components: { CheckBox, Icon, Textarea, DatePicker, Dropdown },
   props: {
      customer: { required: true }
   },
   data() {
      return {
         commentEdit_id: null,
         comment: null,
         contactId: null,
         date: null,
         dateNextContact: null,
      }

   },
   static: {
      iconGroup: Comment,
      iconSend: Send,
      iconDelete: Delete,
      iconEdit: Edit
   },
   methods: {
      getType(type) {
         if (type == 'bch') return 'Bildung-Schweiz'
      },
      getDate(date) {
         if (!date) return null
         return new Day(date).toEuDate()
      },
      onCommentSend() {
         if (!this.comment) return
         this.comment = this.comment.trim()
         if (!this.comment) return

         let contact = this.customer.contacts.find(contact => contact._id == this.contactId)

         let comment = {
            _id: utils.ObjectId(),
            customer_id: this.customer._id,
            type: contact?.type || null,
            date: this.date,
            dateNextContact: this.dateNextContact,
            contactId: contact?._id || null,
            contactName: contact?.name || null,
            comment: this.comment,
            userName: portalState.user.userName,
            userId: portalState.user.userId,
            timeStamp: Timestamp(),
         }

         this.customer.customerCommunication.push(comment)
         this.comment = null
         this.date = new Day().date
         this.dateNextContact = null
         api.post('customer/customerCommunication', comment)
      },
      onCommentDelete(_id) {
         let index = this.customer.customerCommunication.findIndex(comment => comment._id == _id)
         this.customer.customerCommunication.splice(index, 1)
         api.delete(`customer/customerCommunication/${_id}`)

      },
      onCommentEdit(_id) {
         let comment = this.customer.customerCommunication.find(comment => comment._id == _id)
         this.commentEdit_id = _id
         this.comment = comment.comment
         this.date = comment.date
         this.dateNextContact = comment.dateNextContact
         this.contactId = comment.contactId
         this.onCommentDelete(_id)
      },
      onTextareaEnter(e) {
         if (e.shiftKey) {
            return
         }
         e.preventDefault()
         this.onCommentSend()
      },
   },
   computed: {
      commentsSorted() {
         let comments = [...this.customer.customerCommunication]
         return comments.sort((a, b) => {
            if (a.date > b.date) return -1
            if (a.date < b.date) return 1
            return a.timeStamp > b.timeStamp ? -1 : 1
         })
      },
      contactDropdownOptions() {
         return {
            listItems: this.contactDropdownListItems,
            listItemKey: '_id',
            listItemValueKey: 'name',

         }
      },
      contactDropdownListItems() {
         return this.customer.contacts.filter(item => item.name)
      },
      datePickerOptions() {
         return {
            max: new Day().date
         }
      }
   },
   watch: {
      customer() {
         this.contactId = null
      }
   },
   created() {
      this.date = new Day().date
      this.contactId = null

   }
}
</script>

<style scoped>
.Communication {
   padding-top: 20px;
}

.title {
   display: flex;
   column-gap: 10px;
   margin-bottom: 15px;
}

.title .Icon {
   font-size: 18px;
}


.comments {
   display: flex;
   flex-direction: column;
   row-gap: 15px;
   overflow-y: auto;
   overflow-x: hidden;
}

Textarea {
   padding: 3px 5px;
   flex-grow: 1;
}

.editor {
   padding-bottom: 15px;
}

.editor .info {
   font-size: 12px;
   color: #a5a2a2;
   padding-bottom: 5px;
   padding-left: 3px;

}

.editor .paramsRow {
   display: flex;
   align-items: center;
   justify-content: space-between;
   padding-bottom: 9px;
   column-gap: 15px;
}

.paramsRow .items {
   display: flex;
   align-items: center;
   column-gap: 15px;
}

.paramsRow .item {
   display: flex;
   align-items: center;
   height: 24px;
   background-color: var(--activeHover);
   border-radius: 3px;
}

.paramsRow .item.right {
   padding-right: 5px;
}

.textarea {
   display: flex;
   background-color: #f9f9f9fc;
   width: 100%;
   padding: 3px 0;
   border-radius: 3px;
}

.textarea.new {
   background-color: var(--activeHover);
   border-radius: 3px;
}


.col {
   display: flex;
   flex-direction: column;

}

.col.left {
   flex-grow: 1;
}

.col.right {
   justify-content: center;
   align-items: center;
   padding: 0 5px;
   position: relative;
}


.comment {
   display: flex;
   align-items: center;
   flex-wrap: wrap;
   background-color: #f9f9f9fc;
}

.commentHeader {
   display: grid;
   grid-template-columns: 1fr 1fr 1fr;
   align-items: center;
   font-size: 11px;
   padding-top: 3px;
   color: #a5a2a2;
}

.commentHeader>div {
   display: flex;
   align-items: center;
}

.commentHeader .middle {
   justify-content: center;
}

.commentHeader .right {
   justify-content: flex-end;
}

.commentHeader .contactName {
   padding-left: 6px;
}

.commentHeader .month {
   padding-left: 6px;
}

.commentHeader .userName {
   padding-right: 6px;
}

.comment .text {
   flex-grow: 1;
   padding-bottom: 6px;
}

.comment .Icon {
   font-size: 14px;
}



.btn {
   padding: 5px;
   border-radius: 3px;
}

.btn.active {
   background-color: #c7e0f3;
}

.comment .btn:hover {
   background-color: var(--activeHover);
}

.filterRow {
   display: flex;
   align-items: flex-start;
   height: 32px;
   gap: 15px;
}

.hoverMenu {
   position: relative;
}

.flyOutBtn {
   anchor-name: --anchor-el;
}

.flyOut {
   position: absolute;
   background-color: #f9f9f9fc;
   border-radius: 3px;
   padding: 5px;
   z-index: 3;
   position-anchor: --anchor-el;
   top: anchor(--anchor-el bottom);
   margin: 0;
}

/* .hoverMenu:hover .flyOut {
   display: block;

} */
</style>