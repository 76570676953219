<template>
   <div id="App"
      class="CustomerServices">
      <div class="col left">
         <Tabs class="large"
            :tabs="tabs"
            :activeTab="activeTab"
            @nav="activeTab = $event" />
         <Services v-if="activeTab?._id == 1 || activeTab?._id == 2"
            ref="Services"
            apiUrl='customer'
            :mode="serviceMode"
            :showBilling="userApp.showBilling"
            @services="services = $event"
            @update="onServiceUpdate" />
         <div v-if="activeTab?._id == 3"
            class="workingDays">
            <WorkingDays apiUrl='customer'
               mode="month"
               @workingDay="onMonthWorkingDay" />
            <WorkingDay ref="MonthWorkingDay"
               apiUrl='myTasks' />
         </div>

      </div>
      <div v-if="userApp.statistics && activeTab?._id == 1"
         class="col right">
         <Tabs class="large"
            :tabs="statisticsTabs"
            :activeTab="activeStatisticsTab"
            @nav="activeStatisticsTab = $event" />
         <StatisticsCompanies v-if="activeStatisticsTab?._id == 1"
            apiUrl='customer'
            :mode="serviceMode"
            :showBilling="userApp.showBilling"
            :services="services"
            @customer="onCustomerClick" />
         <template v-if="activeStatisticsTab?._id == 2">
            <WorkingDays apiUrl='customer'
               @workingDay="onWorkingDay" />
            <WorkingDay ref="WorkingDay"
               apiUrl='myTasks' />
         </template>

      </div>

   </div>
</template>

<script>
import Tabs from '@components/Navigation/Tabs.vue';
import Services from '../../components/services/Services.vue';
import StatisticsCompanies from './StatisticsCompanies.vue';
import WorkingDays from '../../components/workingDays/WorkingDays.vue';
import WorkingDay from '../../components/workingDays/WorkingDay.vue';

export default {
   name: 'CustomerServices',
   components: { Services, Tabs, StatisticsCompanies, WorkingDays, WorkingDay, },
   props: {
      userApp: { required: true },
   },
   data() {
      return {
         tabs: [
            { _id: 1, text: 'Leistungen', },
            { _id: 2, text: 'Abgerechnet', },
            { _id: 3, text: 'Arbeitsmonat', show: this.userApp.statistics, }
         ],
         activeTab: {},
         services: [],
         statisticsTabs: [
            { _id: 1, text: 'Kunden', },
            { _id: 2, text: 'Arbeitswoche', },
         ],
         activeStatisticsTab: {},
      }
   },
   methods: {
      onServiceUpdate() {
         if (this.$refs.WorkingDay) {
            this.$refs.WorkingDay.update()
         }
         if (this.$refs.WorkingDays) {
            this.$refs.WorkingDays.getData()
         }
      },
      onWorkingDay(employee_id, date, employee) {
         this.$refs.WorkingDay.show(employee_id, date, employee)
      },
      onMonthWorkingDay(employee_id, date, employee) {
         this.$refs.MonthWorkingDay.show(employee_id, date, employee)
      },
      onCustomerClick(customer_id) {
         this.$refs.Services.serviceGridFilters = [{ type: 'customer', value: customer_id }]
      }
   },
   computed: {
      serviceMode() {
         return this.activeTab?._id == 2 ? 'billed' : 'current'
      }
   },
}
</script>

<style scoped>
.CustomerServices {
   display: flex;
   column-gap: 125px;
}

.col {
   display: flex;
   flex-direction: column;
}

.col.right {
   row-gap: 25px;
}

.Services {
   margin-top: 35px;
}

.workingDays {
   display: flex;
   flex-direction: column;
   row-gap: 25px;
}
</style>