<template>
   <div class="Statistics">
      <div class="total">
         <div class="row">
            <div class="item">
               <span>Offene Arbeiten</span>
               <span>{{ total }}</span>
            </div>
         </div>
         <div class="row">
            <div class="item"
               @click="onFilter('done')">
               <div class="color done">
                  <Icon v-if="filter == 'done'"
                     icon="iconCancel" />
               </div>
               <span>Fertig</span>
               <span>{{ doneTotal }}</span>
            </div>
            <div class="item"
               @click="onFilter('wip')">
               <div class="color wip">
                  <Icon v-if="filter == 'wip'"
                     icon="iconCancel" />
               </div>
               <span>In Arbeit</span>
               <span>{{ wipTotal }}</span>
            </div>
            <div v-if="waitTotal"
               class="item"
               @click="onFilter('wait')">
               <div class="color wait">
                  <Icon v-if="filter == 'wait'"
                     icon="iconCancel" />
               </div>
               <span>Abwarten</span>
               <span>{{ waitTotal }}</span>
            </div>
            <div v-if="newTotal"
               class="item"
               @click="onFilter('new')">
               <div class="color new">
                  <Icon v-if="filter == 'new'"
                     icon="iconCancel" />
               </div>
               <span>Neu</span>
               <span>{{ newTotal }}</span>
            </div>
         </div>
         <div class="row">
            <div class="item "
               @click="onFilter('days30Total')">
               <div class="color days30Total ">
                  <Icon v-if="filter == 'days30Total'"
                     icon="iconCancel" />
               </div>
               <span>>30 Tage</span>
               <span>{{ days30Total }}</span>
            </div>
            <div class="item"
               @click="onFilter('days180Total')">
               <div class="color days180Total">
                  <Icon v-if="filter == 'days180Total'"
                     icon="iconCancel" />
               </div>
               <span>>180 Tage</span>
               <span>{{ days180Total }}</span>
            </div>
            <div class="item"
               @click="onFilter('creditTotal')">
               <div class="color creditTotal">
                  <Icon v-if="filter == 'creditTotal'"
                     icon="iconCancel" />
               </div>
               <span>Gutschriften</span>
               <span>{{ creditTotal }}</span>
            </div>
         </div>

      </div>
      <Grid :gridColumns="gridColumns"
         :data="serviceTotalsFiltered"
         :pageSize="null"
         @recordClick="onRecordClick" />
   </div>
</template>

<script>
import Tabs from '@components/Navigation/Tabs.vue';
import Grid from './dataGrid/Grid.vue'
import Color from './dataGrid/Color.vue'
import Icon from "@icons/Icon.vue"
import { Cancel } from "@icons/appFabric/icons"
import { isoGetDate } from '../../utils/utils';
import { Day } from '@SyoLab/date-time'

export default {
   name: 'StatisticsCompanies',
   components: { Tabs, Grid, Icon },
   props: {
      apiUrl: { required: true },
      mode: { required: true },
      showBilling: { required: true },
      services: { required: true },
   },
   data() {
      return {
         filter: null
      }
   },
   static: {
      iconCancel: Cancel
   },
   methods: {
      onRecordClick(record) {
         this.$emit('customer', record.customer_id)
      },
      onFilter(filter) {
         this.filter = this.filter == filter ? null : filter
      }
   },
   computed: {
      notBillableTotals() {
         let totals = {}
         this.services.forEach(service => {
            if (!service.notBillable) return
            if (service.type == 'project') return
            if (service.billNo) return

            if (!totals[service.customer_id]) totals[service.customer_id] = {
               _id: service.customer_id,
               customer_id: service.customer_id,
               customerName: service.customerName,
               status: 'notBillable',
               total: 0,
               lastUpdate: null,
               created: null,
            }

            let value = service.value || 0
            totals[service.customer_id].total += value

         })

         // turn map into array
         return Object.values(totals)


      },
      serviceTotals() {

         let totals = {}
         this.services.forEach(service => {
            if (service.notBillable) return
            if (service.type == 'project') return
            if (service.billNo) return
            if (['new', 'wait', 'wip', 'done'].indexOf(service.status) == -1) return

            if (!totals[service.customer_id]) totals[service.customer_id] = {
               _id: service.customer_id,
               customer_id: service.customer_id,
               customerName: service.customerName,
               status: null,
               total: 0,
               newTotal: 0,
               waitTotal: 0,
               wipTotal: 0,
               doneTotal: 0,
               creditTotal: 0,
               lastUpdate: null,
               created: null,
            }
            let value = service.value || 0
            // totals
            totals[service.customer_id].total += value
            // statusTotal
            let key = service.status + 'Total'
            totals[service.customer_id][key] += value > 0 ? value : 0
            totals[service.customer_id][key] = Math.round(totals[service.customer_id][key] * 100) / 100
            // creditTotal
            totals[service.customer_id].creditTotal += value < 0 ? value : 0
            totals[service.customer_id].creditTotal = Math.round(totals[service.customer_id].creditTotal * 100) / 100

            // lastUpdate
            let lastUpdate = isoGetDate(service.createdTimeStamp)
            if (Array.isArray(service.timeEntrys) && service.timeEntrys.length > 0) {
               lastUpdate = service.timeEntrys.reduce((acc, timeEntry) => {
                  let d = isoGetDate(timeEntry.date)
                  if (!acc || d > acc) acc = d
                  return acc
               }, lastUpdate)
            }
            if (!totals[service.customer_id].lastUpdate || lastUpdate > totals[service.customer_id].lastUpdate) {
               totals[service.customer_id].lastUpdate = lastUpdate
            }

            // created
            let created = isoGetDate(service.createdTimeStamp)
            if (Array.isArray(service.timeEntrys) && service.timeEntrys.length > 0) {
               created = service.timeEntrys.reduce((acc, timeEntry) => {
                  let d = isoGetDate(timeEntry.date)
                  if (!acc || d < acc) acc = d
                  return acc
               }, created)
            }
            if (!totals[service.customer_id].created || created < totals[service.customer_id].created) {
               totals[service.customer_id].created = created
            }
            // done overrides
            if (service.status == 'done') {
               totals[service.customer_id].status = 'done'
            } else if (service.status == 'wip') {
               if (totals[service.customer_id].status != 'done') {
                  totals[service.customer_id].status = 'wip'
               }
            } else if (service.status == 'wait') {
               if (totals[service.customer_id].status != 'done' && totals[service.customer_id].status != 'wip') {
                  totals[service.customer_id].status = 'wait'
               }
            } else if (service.status == 'new' && !totals[service.customer_id].status) {
               totals[service.customer_id].status = 'new'
               totals[service.customer_id].lastUpdate = null
               totals[service.customer_id].created = null
            }
         })

         // turn map into array
         let records = Object.values(totals)
         records.sort((a, b) => b.total - a.total)
         records = records.map(record => {
            record.gridValue = record.total.toLocaleString('de-CH', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
            return record
         })
         return records
      },
      serviceTotalsFiltered() {
         let records = this.serviceTotals.concat(this.notBillableTotals)
         if (this.filter) {
            records = this.serviceTotals.filter(record => {
               if (this.filter == 'days30Total') {
                  return record.lastUpdate && new Day().diff(new Day(record.lastUpdate), 'days') > 30
               }
               if (this.filter == 'days180Total') {
                  return record.lastUpdate && new Day().diff(new Day(record.created), 'days') > 180
               }
               if (this.filter == 'creditTotal') {
                  return record.creditTotal < 0
               }
               return record.status == this.filter
            })
         }
         let filterKey = `total`
         if (this.filter && this.filter != 'days30Total' && this.filter != 'days180Total') {
            filterKey = this.filter + 'Total'
         }

         records.sort((a, b) => b[filterKey] - a[filterKey])
         records = records.map(record => {
            record.gridValue = record.total.toLocaleString('de-CH', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
            return record
         })
         return records
      },
      total() {
         let value = this.serviceTotals.reduce((acc, record) => acc + record.total, 0)
         return value ? value.toLocaleString('de-CH', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : ''
      },
      newTotal() {
         let value = this.serviceTotals.reduce((acc, record) => acc + record.newTotal, 0)
         return value ? value.toLocaleString('de-CH', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : ''
      },
      waitTotal() {
         let value = this.serviceTotals.reduce((acc, record) => acc + record.waitTotal, 0)
         return value ? value.toLocaleString('de-CH', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : ''
      },
      wipTotal() {
         let value = this.serviceTotals.reduce((acc, record) => acc + record.wipTotal, 0)
         return value.toLocaleString('de-CH', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
      },
      doneTotal() {
         let value = this.serviceTotals.reduce((acc, record) => acc + record.doneTotal, 0)
         return value.toLocaleString('de-CH', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
      },
      days30Total() {
         let value = this.serviceTotals.reduce((acc, record) => {
            if (record.lastUpdate && new Day().diff(new Day(record.lastUpdate), 'days') > 30) return acc + record.total
            return acc
         }, 0)
         return value.toLocaleString('de-CH', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
      },
      days180Total() {
         let value = this.serviceTotals.reduce((acc, record) => {
            if (record.lastUpdate && new Day().diff(new Day(record.created), 'days') > 180) return acc + record.total
            return acc
         }, 0)
         return value.toLocaleString('de-CH', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
      },
      creditTotal() {
         let value = this.serviceTotals.reduce((acc, record) => acc + record.creditTotal, 0)
         return value.toLocaleString('de-CH', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
      },
      gridColumns() {
         let totalKey = 'total'
         if (this.filter == 'done') totalKey = 'doneTotal'
         if (this.filter == 'wip') totalKey = 'wipTotal'
         if (this.filter == 'new') totalKey = 'newTotal'
         return [
            { name: 'color', grid: 'color', width: '25px', text: '', component: Color },
            {
               name: 'total',
               grid: 'value',
               width: 'max-content',
               text: 'Betrag',
               emit: 'recordClick',
               value(record, column) {
                  let value = record[totalKey] || 0
                  if (!value) return null
                  return Number(value).toLocaleString('de-CH', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
               },
            },
            { name: 'customerName', grid: 'name', width: '300px', text: 'Firma', class: 'left', emit: 'recordClick' },
            {
               name: 'lastUpdate', grid: 'lastUpdate', width: '130px', text: 'letzte Änderung', class: 'left', emit: 'recordClick',
               value(record, column) {
                  let date = record.lastUpdate
                  return date ? new Day(date).toEuDate() : null
               },
               style(record, column) {
                  let date = record.lastUpdate
                  if (new Day().diff(new Day(date), 'days') > 30) {
                     return { color: 'red' }
                  }
                  return {}
               }
            },
            {
               name: 'created', grid: 'created', width: '90px', text: 'Erstellt', class: 'left', emit: 'recordClick',
               value(record, column) {
                  let date = record.created
                  return date ? new Day(date).toEuDate() : null
               },
               style(record, column) {
                  let date = record.created
                  if (new Day().diff(new Day(date), 'days') > 180) {
                     return { color: 'red' }
                  }
                  return {}
               }
            },
         ]
      },
   },
}
</script>

<style scoped>
.StatisticsCompanies {
   display: flex;
   flex-direction: column;
}

.total {
   display: flex;
   flex-direction: column;
   gap: 15px;
   padding-bottom: 30px;
   padding-left: 3px;
}

.total .row {
   display: flex;
   gap: 25px;
}

.total .item {
   display: flex;
   align-items: center;
   column-gap: 10px;
   font-weight: 500;
   cursor: pointer;
}

.total .color {
   width: 16px;
   height: 16px;
   display: flex;
   align-items: center;
   justify-content: center;
   font-size: 14px;
}

.color.wip {
   background-color: #66CCFF;
}

.color.done {
   background-color: #469E9C;
   color: white
}

.color.wait {
   background-color: #F4F6F8;
}

.color.new {
   background-color: #FF7574;
}

.color.days30Total,
.color.days180Total,
.color.creditTotal {
   border: 1px solid red;
}
</style>