import { render, staticRenderFns } from "./StatisticsCompanies.vue?vue&type=template&id=33db5587&scoped=true"
import script from "./StatisticsCompanies.vue?vue&type=script&lang=js"
export * from "./StatisticsCompanies.vue?vue&type=script&lang=js"
import style0 from "./StatisticsCompanies.vue?vue&type=style&index=0&id=33db5587&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "33db5587",
  null
  
)

export default component.exports